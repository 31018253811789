.auth-header-text{
    font-family: 'Graphik Bold', 'Poppins', sans-serif;
    color: #ffffff !important;
    font-size: 4rem !important;
    text-align: center;
}

.sign-background {
    background: linear-gradient(to bottom, rgba(45,47,58,0.9) 0%, rgba(45,47,58, 0.9) 10%, rgba(45,47,58, 0.9) 20%,rgba(45,47,58, 0.9) 40%, rgba(45,47,58, 0.9) 60%,rgba(45,47,58, 0.9) 100%), url("../../assets/images/spacex.jpg") no-repeat center center scroll;
    background-size: cover;
}

.demo-anchor {
    color: inherit;
}

.demo-anchor:hover  {
    color: #e56c4e !important;
}

.field-body label {
    color: #FFFFFF;
}

.auth-field label {
    color: #FFFFFF;
}

.help p {
    color: #8F9096!important;
}