*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: 100%;
  overflow-x: hidden;
}

.admin {
  --spacing: 1rem;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  height: 100vh;
  grid-template-rows: 70px 1fr;
  grid-template-columns: 250px 1fr;
  grid-template-areas: "nav main" "nav    main";
}
.admin__header {
  display: flex;
  flex-basis: 100%;
  grid-area: header;
  height: 70px;
  background-color: #2D2F3A;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */
  position: relative;
}

.admin-header{
    color: #FF7957;
}
.admin__nav {
  flex: 0 0 250px;
  grid-area: nav;
  /* background-color: #313541; */
  background-color: #2D2F3A;
  /* box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.16); */
  box-shadow: 10px 0px 8px -8px rgba(0, 0, 0, 0.16);
  z-index: 1
}
.admin__main {
  position: relative;
  flex: 1;
  grid-area: main;
  padding: var(--spacing);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* background-color: #f4f7fa; */
  background: rgb(45,47,58);
  background: linear-gradient(132deg, rgba(45,47,58,1) 0%, rgba(74,76,85,1) 50%, rgba(45,47,58,1) 100%);
}

/* .form-card {
  background: rgb(45,47,58);
  background: linear-gradient(132deg, rgba(45,47,58,1) 0%, rgba(74,76,85,1) 50%, rgba(45,47,58,1) 100%);
} */

.overlay {
  position:absolute ;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,rgba(0,0,0, 0.8) 0%,rgba(0,0,0, 0.8) 100%);
  z-index: 1000;
}

.coming-soon-span {
  color: #66676F !important
}


/* .admin__footer {
  display: flex;
  grid-area: footer;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 var(--spacing);
  color: #4e5561;
  background-color: #1d2127;
}
@media screen and (min-width: 48rem) {
  .admin {
    --spacing: 2rem;
  }
} */

.dashboard {
  --column-count: 2;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--spacing) * -0.5);
  display: grid;
  grid-template-columns: repeat(var(--column-count), 1fr);
  grid-gap: var(--spacing);
}

.dashboard__item {
  flex: 1 1 50%;
  /* grid-column-end: span 2; */
  grid-column-end: auto;
  padding: calc(var(--spacing) / 2);
}

.dashboard-item-header{
    color:#FFFFFF !important;
    font-family: 'Graphik Bold', 'Poppins', sans-serif;
}

.dashboard__item--full {
  flex-basis: 100%;
  grid-column: 1 / -1;
}

.dashboard__item--half {
  flex-basis: 100%;
  grid-column: 1 / -1;
}

.dashboard__item--col {
  flex-basis: calc(100% / var(--column-count));
  grid-column-end: span 1;
}

.dashboard__item--chart {
  flex: 1 1 100%;
  /* grid-column-end: span 2; */
  grid-column-end: span 2;
  /* padding: calc(var(--spacing) / 2); */

}

@media screen and (min-width: 48rem) {
  .dashboard {
    --column-count: 4;
  }
}
@supports (display: grid) {
  .dashboard {
    margin: 0;
  }
  .dashboard__item {
    padding: 0;
  }
}

a {
  color: #dc5a60;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.logo {
  display: flex;
  flex: 0 0 250px;
  height: 70px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  color: #fff;
  /* background-color: #dc5a60; */
  background-color: #2D2F3A;
  font-size: 1rem;
}
.logo h1 {
  margin: 0;
}

.toolbar {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing);
  border-bottom: none !important;
  /* border-bottom: 1px solid #66676F; */
  /* box-shadow: 0 10 5px -5px rgba(0, 0, 0, 0.16) !important; */
  /* z-index: 1 !important; */
}

.menu {
  list-style-type: none;
  padding: 0;
}
.menu__item {
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.07); */
}
.menu__link {
  display: block;
  padding: 1rem 2rem;
  color: #76808f;
  text-decoration: none;
}
.menu__link:hover {
  color: #fff;
  background-color: #1f222d;
}

/* .menu__link:not([href]) {
  color: #76808f !important;
} */


.menu__link {
  color: #76808f !important;
}


.menu__link.active {
  color: #FF7957 !important;
}


.card {
  height: 100%;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 300;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.dashboard-header-card {
    background-color: #66676F !important;
    color: #FFFFFF !important;
    height: 50% !important;
    text-align: center !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 2px !important;
    border: none !important;
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
}

.no-dai-balance-card {
  background-color: #2D2F3A !important;
  color: #FFFFFF !important;
  height: 80vh !important;
  text-align: center !important;
  font-family: 'Roboto', sans-serif !important;
  border-radius: 2px !important;
  border: none !important;
  box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
}

.gw-card-dark-flat {
    background-color: #2D2F3A !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 2px !important;
    border: none !important;
}

.btn {
  display: inline-block;
  border-radius: 5em;
  border: 0;
  padding: 0.5rem 1rem;
  white-space: nowrap;
}
.btn--primary {
  color: #fff;
  background-color: #56bf89;
}

.ticker {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.ticker__item {
  margin-right: 1rem;
}

.logged-in-as {
  padding: 0rem 2rem;
  color: #76808f !important;
  margin-bottom: 17%;
}

.inactive-link {
  pointer-events: none;
  cursor: default;
  opacity: 0.2
}

.greenwood-table {
  background-color: transparent !important;
  font-family: 'Roboto', sans-serif !important;
}

.greenwood-table th {
  font-size: 1rem;
  color: #66676F !important;
  border-bottom: 3px solid #40414B !important;
}

/* .table td, .table th {
  border-top: 1px solid red !important;
} */

.greenwood-table tr td {
  font-size: 1rem;
  color: #66676F !important;
  /* border-top: 1px solid #000000 !important; */
  border-bottom: 1px solid #40414B !important;
}


.greenwood-nav-tab {
  width: 40%;
}

.form-card {
  padding: 0 !important;
  background: #2D2F3A !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 2px !important;
}

.modal-card {
  background: rgb(45,47,58) !important;
  background: linear-gradient(132deg, rgba(45,47,58,1) 0%, rgba(74,76,85,1) 50%, rgba(45,47,58,1) 100%) !important;
  box-shadow: none !important;
  border: none !important;
}

.deposit-input {
  background: transparent !important;
  /* background: #66676F !important; */
  margin: 0 auto !important;
  max-width: 100%;
  min-width: 400px;
  border: none !important;
  font-size: 10rem !important;
  font-family: 'Graphik Bold', 'Poppins', sans-serif;
  text-align: center;
  color: transparent !important;
  text-shadow: 0 0 0 #FFFFFF !important;
  cursor: pointer;
  /* border-bottom: 1px solid #66676F; */
}

.deposit-input:focus, .deposit-input:active {
  text-decoration: none !important;
  outline: 0 !important;
  border: none !important;
  box-shadow: none!important;
}

.deposit-input::-webkit-inner-spin-button, 
.deposit-input::-webkit-outer-spin-button { 
  -webkit-appearance: none !important; 
  margin: 0 !important; 
}

.deposit-input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF !important;
  opacity: 1 !important; /* Firefox */
}

.deposit-input:focus::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF !important;
  opacity: 1 !important; /* Firefox */
}

.deposit-token-input {
  background: #66676F !important;
  margin: 0 auto !important;
  max-width: 50%;
  border: none !important;
  /* font-size: 10rem !important; */
  font-family: 'Roboto', sans-serif !important;
  text-align: center;
  border-radius: 2px !important;
  color: #2D2F3A !important;
  cursor: not-allowed
  /* text-shadow: 0 0 0 #FFFFFF !important; */
}

.deposit-input-input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF !important;
  opacity: 1 !important; /* Firefox */
}

.purchase-account-select {
  background: #66676F !important;
  margin: 0 auto !important;
  max-width: 50%;
  border: none !important;
  /* font-size: 10rem !important; */
  font-family: 'Roboto', sans-serif !important;
  text-align: center;
  border-radius: 2px !important;
  color: white !important;
  text-align-last:center;

  /* remove border radius code */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" version="1"><path d="M4 8L0 4h8z"/></svg>');
  padding: .5em;
  padding-right: 1.5em;
  cursor:pointer;
}

.purchase-account-select:focus, .purchase-account-select:active {
  text-decoration: none !important;
  outline: 0 !important;
  border: none !important;
  box-shadow: none!important;
}

.compound-form-button {
  margin: 0 auto !important;
  min-width: 20% !important;
  background: #FF7957 !important;
  color: white !important;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
  min-height: 60px !important;
  font-size: 1.3rem !important;
  transition: box-shadow .25s ease,transform .25s ease, background-color .25s ease;
  text-decoration: none !important;
  text-align: center !important;

  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.compound-form-button:hover{
  background: #e56c4e !important;
  box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
  transform: translate3d(0,-3px,0);
  text-decoration: none !important;
}

.compound-deposit-token-input {
  background: #66676F !important;
  margin: 0 auto !important;
  max-width: 20%;
  border: none !important;
  /* font-size: 10rem !important; */
  font-family: 'Roboto', sans-serif !important;
  text-align: center;
  border-radius: 2px !important;
  color: #2D2F3A !important;
  cursor: not-allowed
  /* text-shadow: 0 0 0 #FFFFFF !important; */
}

.nav-item-50-percent {
  width: 50% !important;
}

.deposit-tab {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 2px !important;
}

.withdraw-tab {
  border-top-left-radius:  0px !important;
  border-top-right-radius: 2px !important;
}

.nav-item-50-percent .active {
  background: #66676F !important;
  color: #2D2F3A !important;
}

.nav-item-50-percent a {
  color: #66676F !important;
}

.nav-link.active {
  border-color: #66676F #66676F #66676F !important;
}


.nav-link .active a {
  color: red !important;
}

.nav-tabs {
  border-bottom: 1px solid #66676F !important;
}

.no-dai-balance {
  background: red !important;
}

.deposit-loader {
  /* height: calc(1.5em + .75rem + 2px);
  font-size: 10rem !important; */
  height: 254px;
} 

 /* Internet Explorer 10-11 */
/* .deposit-input:-ms-input-placeholder {
  color: red !important;
} */

/* Microsoft Edge */
/* .deposit-input::-ms-input-placeholder {
  color: red !important;
} */
