/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aligner-item {
  max-width: 80%;
}

.landing-aligner {
  height: 100vh;
  width: 100vw;
  background-color: #192230;
  /* -webkit-box-shadow:inset 0px 0px 0px 10px #a0adc3;
  -moz-box-shadow:inset 0px 0px 0px 10px #a0adc3;
  box-shadow:inset 0px 0px 0px 10px #a0adc3; */
}

.landing-aligner-item {
  height: 80%;
  width: 100%;
  text-align: center;
  /* background-image: url( './assets/images/gw_outline.png' ); */
  background-size: 105%;
  background-repeat: no-repeat;
  background-position: center;
  /* display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column; */
}

.blue-outline {
  outline: 4px solid #192230;
}

.font-frank-light {
  font-family: 'Frank Ruhl Libre', serif;
  font-weight: 300;
}

.font-color-blue {
  color: #192230;
}

.font-color-white {
  color: #FFFFFF;
}

.landing-h1 {
  font-size: 5.5rem;
  margin-bottom: 0px;
}