.auth-header-text{
    font-family: 'Graphik Bold', 'Poppins', sans-serif;
    color: #ffffff !important;
    font-size: 4rem !important;
    text-align: center;
}

.login-background {
    background: linear-gradient(to bottom, rgba(45,47,58,0.9) 0%, rgba(45,47,58, 0.9) 10%, rgba(45,47,58, 0.9) 20%,rgba(45,47,58, 0.9) 40%, rgba(45,47,58, 0.9) 60%,rgba(45,47,58, 0.9) 100%), url("../../assets/images/spacex.jpg") no-repeat center center scroll;
    background-size: cover;
}

.demo-anchor {
    color: inherit;
}

.demo-anchor:hover  {
    color: #e56c4e !important;
}

.auth-form-field {

}

.field-body {
    margin-bottom: 0.5rem !important;
}

.field-body label {
    color: #FFFFFF;
}

.auth-field label {
    color: #FFFFFF;
}

input[type=checkbox] {
    background-color: red !important;
    margin-left:-18px !important;
}

.checkbox-label {
    margin-left:18px !important;
}
/* .auth-form-field{
    height: 3.25rem;
} */

.help p {
    color: #8F9096!important;
}

.register-container {
    padding: 0 !important;
}