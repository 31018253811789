.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Aligner-item {
    max-width: 50%;
  }

  .oauth-aligner {
    background: linear-gradient(132deg, rgba(45,47,58,1) 0%, rgba(74,76,85,1) 50%, rgba(45,47,58,1) 100%);
  }