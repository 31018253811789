.demo-background {
    background: linear-gradient(to bottom, rgba(45,47,58,0.8) 0%, rgba(45,47,58, 0.8) 10%, rgba(45,47,58, 0.8) 20%,rgba(45,47,58, 0.8) 40%, rgba(45,47,58, 0.8) 60%,rgba(45,47,58, 0.8) 100%), url("../../assets/images/dashboard_coming_soon_bw.png") no-repeat center center scroll;
    background-size: cover;
}

.demo-anchor {
    color: inherit;
}

.demo-anchor:hover  {
    color: #e56c4e !important;
}

article {
    font-family: 'Graphik Bold', 'Poppins', sans-serif;
    color: #FFFFFF !important
}