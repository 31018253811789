@font-face {
  font-family: 'Graphik Bold';
  src: local('Graphik Bold'), url('./assets/fonts/Graphik/GraphikBold.otf') format('otf');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Cerebri Sans Regular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #2D2F3A !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cs-extra-bold {
  font-family: 'Cerebri Sans ExtraBold';
}

.roboto-regular {
  font-family: 'Roboto', sans-serif !important;
}

.graphik-bold {
  font-family: 'Graphik Bold', 'Poppins', sans-serif;
}

.color-orange {
  color: #FF7957;
}

.access-button {
  background: #FF7957 !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
  min-height: 60px !important;
  min-width: 20% !important;
  font-size: 1.3rem !important;
  transition: box-shadow .25s ease,transform .25s ease, background-color .25s ease;
  text-decoration: none !important;
  /* font-family: 'Graphik Bold' !important; */
}

.access-button:hover{
  background: #e56c4e !important;
  box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
  transform: translate3d(0,-3px,0);
  text-decoration: none !important;
}

.lift {
  transition: box-shadow .25s ease,transform .25s ease-in-out;
}

a:hover {
  text-decoration: none !important;
}