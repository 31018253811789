.mobile-responsive-card-text {
    font-family: 'Graphik Bold', 'Poppins', sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.5em !important;
  }
  
  
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .mobile-seperator {
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
      
  }
  
  
  
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .mobile-responsive-card-text {
      font-family: 'Graphik Bold', 'Poppins', sans-serif !important;
      font-weight: 600 !important;
      font-size: 1em !important;
    }
  }