body{
    background: white !important;
}

@font-face {
    font-family: 'Cerebri Sans Regular';
    src: local('CerebriSans-Regular'), url('../../assets/fonts/Cerebri-Sans/CerebriSans-Regular.woff') format('woff');
}
  
@font-face {
    font-family: 'Cerebri Sans ExtraBold';
    src: local('CerebriSans-ExtraBold'), url('../../assets/fonts/Cerebri-Sans/CerebriSans-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Graphik Semibold';
    src: local('Graphik Semibold'), url('../../assets/fonts/Graphik/GraphikSemibold.otf') format('otf');
}

.color-white {
    color: #ffffff !important;
}

.color-light-grey {
    color: #66676F !important;
}

.color-dark-blue {
    color : #2D2F3A !important;
}


.header-background{
    /* background: url("../../assets/images/skyscrapers.png") no-repeat center; */
    /* background: linear-gradient(to bottom, rgba(45,47,58,0.7) 0%, rgba(45,47,58, 0.7) 10%, rgba(45,47,58, 0.7) 20%,rgba(45,47,58, 0.7) 40%, rgba(45,47,58, 0.8) 60%,rgba(45,47,58, 1) 100%), url("../../assets/images/skyscrapers.png") no-repeat center center scroll; */
    background: white !important;
    background-size: cover;
}

.header-text-contract{
    font-family: 'Graphik Semibold', 'Poppins', sans-serif;
    color: #2D2F3A !important;
    font-size: 4rem !important;
    text-align: center !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .header-text-contract {
        font-family: 'Graphik Semibold', 'Poppins', sans-serif !important;
        color: #2D2F3A !important;
        font-size: 1.15rem !important;
        margin-bottom: 5% !important;
        text-align: center !important;
    }
    
}

.header-subtitle-contract {
    color: #66676F !important;
    margin-top: 1% !important;
    margin-bottom: 3% !important;
    text-align: center !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) {
    .header-subtitle-contract {
        color: #66676F !important;
        margin-top: 0 !important;
        margin-bottom: 3% !important;
        font-size: 0.7rem !important;
        text-align: center !important;
    }
}

.integrations-title-contract {
    color: #2D2F3A !important;
    text-align: center !important;
    font-weight: 300 !important;
    /* font-size: 1.5rem !important; */
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) { 
    .integrations-title {
        font-size: 0.7rem !important;
     }
}

.logo-column{
    text-align: center !important;
}

.logo-image {
    width: 180px;
    height: 28px;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .logo-image {
        width: 90px;
        height: 14px;
    }
    
}

.feature-pod-contract {
    border-left: 3px solid #FF7957;
    display: flex !important;
    align-items: center !important;
    /* text-align: center; */
}

.call-to-action-contract {
    background: white;
}

.footer-contract {
    background: white !important;
    font-family: 'Roboto', sans-serif !important;
    padding-bottom: 0 !important;

}

.footer-anchor {
    color: inherit;
}

.landing-navbar-item {
    color: #ffffff !important;
}

.landing-navbar-item:hover {
    background: transparent!important;
}

.landing-navbar-button-filled {
    background: #FF7957 !important;
    color: #ffffff !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 4px !important;
    border: none !important;
    min-width: 7vw !important;
    min-height: 45px !important;
}

.landing-navbar-button-filled:hover {
    border: none !important;
    background: #e56c4e !important;
}

.landing-navbar-button-empty {
    background: transparent !important;
    color: #FFFFFF !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 4px !important;
    border: 1px solid #FFFFFF !important;
    min-width: 7vw !important;
    min-height: 45px !important;
}

.landing-navbar-button-empty:hover {
    background: white !important;
    color: #FF7957 !important;
}

.color-orange {
    color: #FF7957 !important;
}

.access-button-contract {
    background: #FF7957 !important;
    color: white !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    min-height: 60px !important;
    min-width: 20% !important;
    font-size: 1.3rem !important;
    transition: box-shadow .25s ease,transform .25s ease, background-color .25s ease;
    text-decoration: none !important;
    margin-top: 5% !important;
    /* font-family: 'Graphik Bold' !important; */
  }

  .access-button-contract:hover{
    background: #e56c4e !important;
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
    text-decoration: none !important;
  }


@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .access-button-contract {
        background: #FF7957 !important;
        color: white !important;
        border: none !important;
        border-radius: 2px !important;
        box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
        min-height: 40px !important;
        min-width: 20% !important;
        font-size: 0.9rem !important;
        transition: box-shadow .25s ease,transform .25s ease, background-color .25s ease;
        text-decoration: none !important;
        margin-top: 5% !important;
        /* font-family: 'Graphik Bold' !important; */
      }
    
}

.product-section {
    background: white !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .product-section {
        padding-top: 0px !important
      }
    
}




.feature-section {
    padding: 5rem 1.5rem !important
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .feature-section {
        padding-top: 0px !important
      }
    
}

.demo-section {
    padding: 5rem 1.5rem !important
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .demo-section {
        padding-top: 0px !important
      }
    
}

.benefit-section {
    padding-top: 2rem !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .benefit-section {
        padding-top: 0px !important;
    }
    
}

.benefit-title-contract {
    font-size: 1.5rem !important;
    font-family: 'Graphik Semibold', 'Poppins', sans-serif;
    
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .benefit-title-contract {
        font-size: 1rem !important;
    }
    
}

.benefit-subtitle-contract {
    font-size: 1rem !important;
    color: #66676F;
}


@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .benefit-subtitle-contract {
        font-size: 0.5rem !important;
    }
    
}

.benefit-container-contract {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    align-items: center;
    text-align: left;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .benefit-container {
        margin-top: 5% !important;
    }
    
}


@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .footer-header {
        font-size: 1rem !important;
        margin-bottom: 1rem !important;
    }
    
}

.footer-contract {
    font-size: 0.5rem !important;
    /* margin-bottom: 1rem !important; */
    color: #66676F;
    padding-top: 3% !important;
}

.footer-text-contract {
    font-size: 0.7rem !important;
    margin-bottom: 1rem !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .footer-text-contract {
        font-size: 0.5rem !important;
        margin-bottom: 1rem !important;
    }
    
}


.main-section-contract {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .main-section-contract {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    
}