@font-face {
    font-family: 'Cerebri Sans Regular';
    src: local('CerebriSans-Regular'), url('../../assets/fonts/Cerebri-Sans/CerebriSans-Regular.woff') format('woff');
}
  
@font-face {
    font-family: 'Cerebri Sans ExtraBold';
    src: local('CerebriSans-ExtraBold'), url('../../assets/fonts/Cerebri-Sans/CerebriSans-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Graphik Bold';
    src: local('Graphik Bold'), url('../../assets/fonts/Graphik/GraphikBold.otf') format('otf');
}

.color-white {
    color: #ffffff !important;
}

.color-light-grey {
    color: #66676F !important;
}

.color-dark-blue {
    color : #2D2F3A !important;
}


.header-background-stag{
    /* background: url("../../assets/images/skyscrapers.png") no-repeat center; */
    min-height: 100% !important;
    min-width: 100% !important;
    background: linear-gradient(to bottom, rgba(45,47,58,0.0) 0%, rgba(45,47,58, 0.0) 10%, rgba(45,47,58, 0.0) 20%,rgba(45,47,58, 0.0) 40%, rgba(45,47,58, 0.0) 60%,rgba(45,47,58, 0) 100%), url("../../assets/images/demo_bg.png") no-repeat center center scroll;
    /* background: rgb(255,255,255); */
    background-size: contain;
    height: 100vh;
}

.header-text-light{
    font-family: 'Graphik Bold', 'Poppins', sans-serif;
    color: #2D2F3A !important;
    font-size: 3.5rem !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .header-text-light {
        font-family: 'Graphik Bold', 'Poppins', sans-serif;
        color: #2D2F3A !important;
        font-size: 1.5rem !important;
        margin-bottom: 3% !important;
    }
    
}

.header-subtitle {
    color: #FFFFFF !important;
    max-width: 50%;
    margin-top: 1% !important;
    margin-bottom: 3% !important;
}

.integrations-title {
    color: #FFFFFF !important;
    text-align: center !important;
    font-weight: 300 !important;
    font-size: 1.5rem !important;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) { 
    .integrations-title {
        font-size: 0.7rem !important;
     }
}

.logo-column{
    text-align: center !important;
}

.feature-pod {
    border-left: 3px solid #FF7957;
}

.call-to-action {
    background: linear-gradient(to bottom, 
    rgba(102,103,111,0.0) 0%, 
    rgba(102,103,111, 0.1) 10%, 
    rgba(102,103,111, 0.2) 20%,
    rgba(102,103,111, 0.3) 30%, 
    rgba(102,103,111, 0.4) 40%,
    rgba(102,103,111, 0.5) 50%,
    rgba(102,103,111, 0.6) 60%,
    rgba(102,103,111, 0.7) 70%,
    rgba(102,103,111, 0.8) 80%,
    rgba(102,103,111, 0.9) 90%,
    rgba(102,103,111, 0.99) 99%);
}

.footer {
    /* background: #66676F !important;
    background: linear-gradient(to bottom, 
    rgba(102,103,111,0.99) 0%, 
    rgba(102,103,111,0.99) 1%, 
    rgba(102,103,111,0.99) 2%, 
    rgba(102,103,111,1) 3%, 
    rgba(102,103,111,1) 4%, 
    rgba(102,103,111,1) 5%, 
    rgba(102,103,111, 1) 100%);
    font-family: 'Roboto', sans-serif !important; */
    background: transparent;;
}

.footer-anchor {
    color: inherit;
}

.landing-navbar-item {
    color: #ffffff !important;
}

.landing-navbar-item:hover {
    background: transparent!important;
}

.landing-navbar-button-filled {
    background: #FF7957 !important;
    color: #ffffff !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 4px !important;
    border: none !important;
    min-width: 7vw !important;
    min-height: 45px !important;
}

.landing-navbar-button-filled:hover {
    border: none !important;
    background: #e56c4e !important;
}

.landing-navbar-button-empty {
    background: transparent !important;
    color: #FFFFFF !important;
    font-family: 'Roboto', sans-serif !important;
    border-radius: 4px !important;
    border: 1px solid #FFFFFF !important;
    min-width: 7vw !important;
    min-height: 45px !important;
}

.landing-navbar-button-empty:hover {
    background: white !important;
    color: #FF7957 !important;
}